<template>
  <div>

    <div class="row">
      <div class="col-md">

        <div class="card shadow mb-5">
          <div class="card-header">
            <div class="d-flex align-items-center">
              <h5>Lampiran {{ dataName }}</h5>
            </div>
            <div>
              <div>{{ new Date(data.date).toLocaleString('id-ID', {day: 'numeric', weekday: 'long', month: '2-digit', year: 'numeric'}).replaceAll('/', '-') }}</div>
            </div>
          </div>
          <div class="card-body">

            <div
              class="row"
              v-if="data != {}"
            >

              <div
                v-for="(data, index) in data.attachment"
                :key="index"
                class="col-md-3 col-12"
              >
                <div
                  class="card shadow-sm border-info"
                  @click="downloadFile(data)"
                >
                  <div class="px-3 py-5">
                    <div class="d-flex align-items-center">
                      <div class="h1"><i class="far fa-fw fa-lg fa-file text-info"></i></div>
                    </div>
                    <div class="mb-2">{{ data.name }}</div>
                    <div>{{ Math.round((data.size / 100) * 100) / 1000 }} Kb</div>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>

        <Card>
          <template v-slot:body>
            <b-button
              class="ml-2"
              variant="default"
              @click="$router.push('/lab-report-2/list')"
            >
              Kembali
            </b-button>
          </template>
        </Card>

      </div>
    </div>

  </div>
</template>

<script>

import module from '@/core/modules/CrudModule.js'
import Card from '@/view/content/Card.vue'

export default {

  data() {
    return {
      data: {},
      dataName: ''
    }
  },

  components: {
    Card
  },

  methods: {

    async get() {
      this.data = await module.get(`lab-reports/${this.$route.params.id}`)
      // If Data Not found
      if (this.data == null) {
        // Redirect To List
        this.$router.push('lab-report/list')
      }
      this.dataName = `${this.data.lab_category_name} ${this.data.doctor_name} - ${this.data.patient_name}`
    },

    downloadFile(data) {
      window.open(window.s3Endpoint + '/' + data.path)
    }

  },

  mounted() {
    this.get()
  }

}
</script>

<style>
</style>